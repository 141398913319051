<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Feedy - seznam</strong>
      </div>
      <action-tools :router-options="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-6"></div>
        <div class="text-right col-md-6">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        tableLayout="auto"
        :rowKey="record => record.id"
        :columns="columns"
        :loading="loading"
        :dataSource="feeds"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
      >
        <router-link
          slot="id"
          slot-scope="value"
          class="utils__link--underlined"
          :to="'/ecommerce/feeds/detail/' + value"
        >#<span v-if="searchText">
            <template v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))" >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value}}</template>
        </router-link>
        <template slot="customRender" slot-scope="text">
          <span v-if="searchText">
            <template v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))" >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <span
          slot="active"
          slot-scope="value"
          :class="[value ? 'font-size-12 badge badge-success' : 'font-size-12 badge badge-danger']"
        >
        <span v-if="searchText">
            <template v-for="(fragment, i) in (value ? 'zapnuto' : 'vypnuto').toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))" >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value ? 'zapnuto' : 'vypnuto'}}</template>
        </span>
        <span
          slot="dates"
          slot-scope="text"
        >
        <span v-if="searchText">
            <template v-for="(fragment, i) in toLocaleDate(text).toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))" >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{toLocaleDate(text)}}</template>
        </span>
        <span slot="action" slot-scope="record">
           <router-link :to="'/ecommerce/feeds/detail/'+record.id">
            <a-button icon="search" size="small" class="mr-1">Zobrazit</a-button>
          </router-link>
          <a-button v-if="record.is_active" @click="powerOffOn(record.id, false)" icon="poweroff" size="small">Vypnout</a-button>
          <a-button v-else icon="poweroff" @click="powerOffOn(record.id, true)" size="small">Zapnout</a-button>
        </span>
        <template slot="footer">
          {{ feeds.length }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
export default {
  components: { ActionTools },
  data: function () {
    return {
      searchText: '',
      loading: false,
      routerOptions: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.id - b.id,
          scopedSlots: {
            customRender: 'id',
          },
        },
        {
          title: 'Název',
          dataIndex: 'name',
          sorter: (a, b) => {
            return a.name.localeCompare(b.name)
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Poslední úprava',
          dataIndex: 'updated_at',
          sorter: (a, b) => {
            return new Date(a.updated_at) - new Date(b.updated_at)
          },
          scopedSlots: {
            customRender: 'dates',
          },
        },
        {
          title: 'Stav',
          dataIndex: 'is_active',
          sorter: (a, b) => {
            return a.is_active - b.is_active
          },
          scopedSlots: {
            customRender: 'active',
          },
        },
        {
          title: 'Akce',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    feeds: function () {
      return this.$store.getters['feeds/allItems'](this.searchText)
    },
  },
  methods: {
    powerOffOn(id, isActive) {
      this.loading = true
      this.$store.dispatch('feeds/put', {
        id: id,
        item: {
          is_active: isActive,
        },
        from: 'LIST',
      })
        .finally(() => {
          this.loading = false
        })
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('feeds/getList')
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
    toLocaleDate: function (text) {
      return this.$options.filters.formatDate(text)
    },
  },
  created() {
    this.handleRefresh()
  },
}
</script>

<style lang="scss" module scoped>

</style>
